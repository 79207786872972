import React, { useEffect, useLayoutEffect, useState } from "react";
import { ConcreteColors, Icon, notify } from "hcss-components";
import Axios from "axios";
import styled from "styled-components";
import {
  AnimatedComponentStatus,
  handleAnimateHide,
  handleAnimateShow,
  CanBlur,
} from "../LoginPageAnimation";
import { LinkButton } from "../../../FormComponents/LinkButton";
import { useTranslation } from "react-i18next";
import ResetPasswordUsernameForm from "./ResetPasswordUsernameForm";
import ResetPasswordOTPForm from "./ResetPasswordOTPForm";
import { LoginFormWrapper } from "../Structure/LoginFormWrapper";
import identityModelService from "../../../../services/IdentityModelService";

interface ResetPasswordFormProps extends CanBlur {
  /** JSON containing tokens from IdentityServer */
  model: any;
  /** determines whether or not the form is visible to the user; triggers hide or show animations */
  isActive: boolean;
  isMobile: boolean;
  /** Callback fired when the password reset request is initiated */
  onProcessRequestStart: () => void;
  /** Callback fired when the password reset request is finished */
  onProcessRequestEnd: () => void;
  /** Callback fired when user requests to return to the login screen */
  onCancel: () => void;
}

enum CurrentResetPasswordFormEnum {
  Username,
  ContactMethod,
  OTP,
}
export enum ContactMethodEnum {
  Email,
  Phone,
}
export interface ITwoFactorAuthModel {
  email: string;
  phoneNumber: string;
  defaultProvider: string;
}
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (
  props: ResetPasswordFormProps
) => {
  const [status, setStatus] = useState<AnimatedComponentStatus>(
    AnimatedComponentStatus.Hidden
  );
  const [currentForm, setCurrentForm] =
    React.useState<CurrentResetPasswordFormEnum>(
      CurrentResetPasswordFormEnum.Username
    );
  const [isProcessingRequest, setIsProcessingRequest] =
    React.useState<boolean>(false);

  const [username, setUsername] = React.useState<string>("");
  const [errorNoSuchUserName, setErrorNoSuchUserName] =
    React.useState<boolean>(false);

  const [otp, setOTP] = React.useState<string>("");
  const [errorOTP, setErrorOTP] = React.useState<boolean>(false);

  const [twoFactorAuthModel, setTwoFactorAuthModel] =
    React.useState<ITwoFactorAuthModel>({
      email: "",
      phoneNumber: "",
      defaultProvider: "",
    });

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (props.isActive) {
      setCurrentForm(CurrentResetPasswordFormEnum.Username);
      handleAnimateShow(status, 20, setStatus);
    } else {
      handleAnimateHide(status, 425, setStatus);
      resetForm();
    }
  }, [props.isActive]);

  useEffect(() => {
    if (isProcessingRequest) props.onProcessRequestStart();
    else props.onProcessRequestEnd();
  }, [isProcessingRequest]);

  const resetForm = () => {
    setUsername("");
    setErrorNoSuchUserName(false);
    setOTP("");
    setErrorOTP(false);
  };

  const handleUsernameSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    if (!isProcessingRequest) {
      setIsProcessingRequest(true);
      event.preventDefault();
      const eventCopy = { ...event };

      submitUsername(eventCopy);
    }
  };

  const submitUsername = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!isProcessingRequest) {
      setIsProcessingRequest(true);
      const formData = new FormData(event.currentTarget);

      const data = {
        resetPasswordInput: formData.get('resetPasswordInput'),
        returnUrl: identityModelService.returnUrl,
      };

      try {
        const response = await Axios.post("/auth/token/generate", data)
        setTwoFactorAuthModel(response.data);
        
      }
      catch(error) {
          // show a fake success on errors . . .
      }
      finally {
          setCurrentForm(CurrentResetPasswordFormEnum.OTP);
          setIsProcessingRequest(false);
      };
    }
  };

  const handleTokenSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!isProcessingRequest) {
      event.preventDefault();
      setIsProcessingRequest(true);
      const formData = new FormData(event.currentTarget);
      const params = new URLSearchParams();
      formData.forEach((value, key) => params.append(key, value.toString()));

      try {
        const response = await Axios.post(event.currentTarget.action, formData);
        window.location.assign(response.data.redirectUrl);
        setIsProcessingRequest(false);
        props.onCancel();
      }
      catch(error) {
          notify("danger", t("error"), t("forgotForm.errorOtp") as string);
          setErrorOTP(true);
          setIsProcessingRequest(false);
        };
    }
  };

  return (
    <LoginFormWrapper
      className="reset-password-form-wrapper"
      status={status}
      blur={props.blur}
      numOfAltLoginMethods={1}
    >
      <CancelButtonContainer>
        <LinkButton onClick={props.onCancel}>
          <Icon name="angle-left" margin="right" />
          {t("forgotForm.back")}
        </LinkButton>
      </CancelButtonContainer>

      <ResetPasswordUsernameForm
        model={props.model}
        isBlurred={isProcessingRequest}
        isActiveForm={currentForm === CurrentResetPasswordFormEnum.Username}
        username={username}
        error={errorNoSuchUserName}
        setUsername={setUsername}
        setError={setErrorNoSuchUserName}
        onSubmit={handleUsernameSubmit}
      />

      <ResetPasswordOTPForm
        model={props.model}
        isBlurred={isProcessingRequest}
        isActiveForm={currentForm === CurrentResetPasswordFormEnum.OTP}
        twoFactorAuthModel={twoFactorAuthModel}
        otp={otp}
        error={errorOTP}
        setOTP={setOTP}
        setError={setErrorOTP}
        onSubmit={handleTokenSubmit}
        userName={username}
      />
    </LoginFormWrapper>
  );
};
export default ResetPasswordForm;

const CancelButtonContainer = styled.div`
  margin-bottom: 1rem;
  z-index: 7;

  & > a {
    color: ${ConcreteColors.blue200};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:focus,
    &:hover,
    &:active {
      color: ${ConcreteColors.blue300};
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 576px) and (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 1.3rem;
  }
`;
